<template>
    <div>
        <div style="display: flex; justify-content: center; align-items: center; text-align:center;"
            class="intro-header">
            <div class="header-text">
                <div style="color: #333333; font-size: 32px; font-weight: bold;" class="header-title">
                    隐私政策
                </div>
                <div style="color: #999999;">
                    Privacy policy
                </div>
            </div>
        </div>
        <div style="padding: 0px 16px; width: 1232px; margin: 0 auto; ">
            <div style="color: #3D3D3D; color: #3D3D3D; padding-bottom: 16px; margin-top: 40px; font-size: 24px;border-bottom: 1px solid #eeeeee;">
                隐私政策
            </div>
            <div style="display: flex; justify-content: center; margin-top: 44px;">
                <div>
                    <p>广州市科颐轩电子科技有限公司（以下简称&ldquo;我们&rdquo;）深知个人信息对您的重要性，并会尽全力保护您的个人信息安全可靠。我们致力于维持您对我们的信任，恪守以下原则，保护您的个人信息：权责一致原则、目的明确原则、选择同意原则、最少够用原则、确保安全原则、主体参与原则、公开透明原则等。同时，我们承诺，我们将按业界成熟的安全标准，采取相应的安全保护措施来保护您的个人信息。
                    </p>
                    <p>请在使用我们的产品（或服务）前，仔细阅读并了解本《隐私权政策》。</p>
                    <p><strong>一、我们如何收集和使用您的个人信息</strong></p>
                    <p>个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。</p>
                    <p>我们仅会出于本政策所述的以下目的，收集和使用您的个人信息：</p>
                    <p>（一）注册成为我们的用户</p>
                    <p>为成为我们的用户，以便于我们为您提供服务，您需提供以下信息：您的姓名、电子邮箱地址、手机号，并创建账号和密码。</p>
                    <p>您提供的上述信息，将在您使用本服务期间持续授权我们使用。在您的账号超过与我们约定的使用服务期限或您主动申请注销账号时，我们将根据适用法律法规的要求尽快使其匿名或删除您的个人信息。</p>
                    <p>上述信息将存储于中华人民共和国境内。如需跨境传输，我们将会单独征得您的授权同意。</p>
                    <p>（二）为您展示和推送信息</p>
                    <p>我们会根据您的成员使用账号进行领用、申购产生的审批申请信息，为您展示和推送审批信息，若您不想接受此类信息，您可随时通过相应产品退订功能取消。</p>
                    <p>（三）开展内部数据分析和研究，第三方SDK统计服务，改善我们的产品或服务</p>
                    <p>我们收集数据是根据您与我们的互动和您所做出的选择，包括您的隐私设置以及您使用的产品和功能。我们收集的数据可能包括SDK/API/JS代码版本、浏览器、互联网服务提供商、IP地址、平台、时间戳、应用标识符、应用程序版本、应用分发渠道、独立设备标识符、iOS广告标识符（IDFA)、安卓广告主标识符、网卡（MAC）地址、国际移动设备识别码（IMEI）、设备型号、终端制造厂商、终端设备操作系统版本、会话启动/停止时间、语言所在地、时区和网络状态（WiFi等）、硬盘、CPU和电池使用情况等。
                    </p>
                    <p>（四）其他用途</p>
                    <p>当我们将信息用于本策略未载明的其它用途时，会事先征求您的同意，或者将基于特定目的收集而来的信息用于其他目的时，会事先征求您的同意。</p>
                    <p>（五）征得授权同意的例外</p>
                    <p>根据相关法律法规规定，以下情形中收集您的个人信息无需征得您的授权同意：</p>
                    <p>1、与国家安全、国防安全有关的；</p>
                    <p>2、与公共安全、公共卫生、重大公共利益有关的；</p>
                    <p>3、与犯罪侦查、起诉、审判和判决执行等有关的；</p>
                    <p>4、出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的；</p>
                    <p>5、所收集的个人信息是您自行向社会公众公开的；</p>
                    <p>6、从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；</p>
                    <p>7、根据您的要求签订合同所必需的；</p>
                    <p>8、用于维护所提供的产品或服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；</p>
                    <p>9、为合法的新闻报道所必需的；</p>
                    <p>10、学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；</p>
                    <p>11、法律法规规定的其他情形。</p>
                    <p>如我们停止运营科颐轩产品或服务，我们将及时停止继续收集您个人信息的活动，将停止运营的通知以逐一送达或公告的形式通知您，对所持有的个人信息进行删除或匿名化处理。</p>
                    <p><strong>二、我们如何使用 Cookie 和同类技术</strong></p>
                    <p>（一）Cookie</p>
                    <p>为确保网站正常运转、为您获得更轻松的访问体验，我们会在您的计算机或移动设备上存储名为 Cookie 的小数据文件。Cookie 通常包含标识符、站点名称以及一些号码和字符。借助于
                        Cookie，网站能够存储您的偏好或购物篮内的商品等数据。</p>
                    <p>我们不会将 Cookie 用于本政策所述目的之外的任何用途。您可根据自己的偏好管理或删除 Cookie。您可以清除计算机上保存的所有 Cookie，大部分网络浏览器都设有阻止 Cookie
                        的功能。但如果您这么做，则需要在每一次访问我们的网站时亲自更改用户设置。如需详细了解如何更改浏览器设置，请访问您使用的浏览器的相关设置页面。</p>
                    <p>（二）网站信标和像素标签</p>
                    <p>除 Cookie 外，我们还会在网站上使用网站信标和像素标签等其他同类技术。例如，我们向您发送的电子邮件可能含有链接至我们网站内容的点击
                        URL。如果您点击该链接，我们则会跟踪此次点击，帮助我们了解您的产品或服务偏好并改善客户服务。网站信标通常是一种嵌入到网站或电子邮件中的透明图像。借助于电子邮件中的像素标签，我们能够获知电子邮件是否被打开。如果您不希望自己的活动以这种方式被追踪，则可以随时从我们的寄信名单中退订。
                    </p>
                    <p><strong>三、我们如何共享、转让、公开披露您的个人信息</strong></p>
                    <p>（一）共享</p>
                    <p>我们不会向其他任何公司、组织和个人分享您的个人信息，但以下情况除外：</p>
                    <p>1、在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的个人信息。</p>
                    <p>2、在法定情形下共享：我们可能会根据法律法规规定、诉讼争议解决需要，或按行政、司法机关依法提出要求的，对外共享您的个人信息。</p>
                    <p>3、与我们的关联公司共享：您的个人信息可能会与我们关联公司共享。我们只会共享必要的个人信息，且受本隐私政策中所声明目的的约束。关联公司如要改变个人信息的处理目的，将再次征求您的授权同意。</p>
                    <p>4、与授权合作伙伴共享：仅为实现本隐私权政策中声明的目的，我们的某些服务将由我们和授权合作伙伴共同提供。我们可能会与合作伙伴共享您的某些个人信息，以提供更好的客户服务和用户体验。例如，我们聘请来提供第三方数据统计和分析服务的公司可能需要采集和访问个人数据以进行数据统计和分析。在这种情况下，这些公司必须遵守我们的数据隐私和安全要求。我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。我们的合作伙伴无权将共享的个人信息用于与产品或服务无关的其他用。
                    </p>
                    <p>（二）转让</p>
                    <p>我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：</p>
                    <p>1、在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息；</p>
                    <p>2、在科颐轩产品或服务提供者发生合并、收购或破产清算时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受此隐私政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。
                    </p>
                    <p>（三）公开披露</p>
                    <p>1、获得您明确同意或基于您的主动选择，我们可能会公开披露您的个人信息；</p>
                    <p>根据法律规定，共享、转让经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，不属于个人信息的对外共享、转让及公开披露行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。
                    </p>
                    <p>（四）共享、转让、公开披露个人信息时事先征得授权同意的例外</p>
                    <p>以下情形中，共享、转让、公开披露您的个人信息无需事先征得您的授权同意：</p>
                    <p>1、与国家安全、国防安全有关的；</p>
                    <p>2、与公共安全、公共卫生、重大公共利益有关的；</p>
                    <p>3、与犯罪侦查、起诉、审判和判决执行等有关的；</p>
                    <p>4、出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</p>
                    <p>5、您自行向社会公众公开的个人信息；</p>
                    <p>6、从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。</p>
                    <p>根据法律规定，共享、转让经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，不属于个人信息的对外共享、转让及公开披露行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。
                    </p>
                    <p><strong>四、我们如何保护您的个人信息安全</strong></p>
                    <p>（一）我们已采取符合业界标准、合理可行的安全防护措施保护您提供的个人信息安全，防止数据遭到未经授权访问、公开披露、使用、修改、损坏或丢失。例如，我们对科颐轩网站提供 https
                        协议安全浏览方式；我们会使用加密技术提高个人信息的安全性；我们会使用受信赖的保护机制防止数据遭到恶意攻击；我们会部署访问控制机制，确保只有授权人员才可访问个人信息；以及我们会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。
                    </p>
                    <p>（二）我们会采取一切合理可行的措施，确保未收集无关的个人信息。我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非需要延长保留期或受到法律的允许。</p>
                    <p>（三）互联网并非绝对安全的环境，而且电子邮件、即时通讯、及与其他我们用户的交流方式并未加密，我们强烈建议您不要通过此类方式发送个人信息。请使用复杂密码，协助我们保证您的账号安全。</p>
                    <p>（四）在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。
                    </p>
                    <p>同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。</p>
                    <p><strong>五、您的权利</strong></p>
                    <p>按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法，我们保障您对自己的个人信息行使以下权利：</p>
                    <p>（一）访问您的个人信息</p>
                    <p>您有权访问您的个人信息，法律法规规定的例外情况除外。如果您想行使数据访问权，可以通过以下方式自行访问：</p>
                    <p>账号信息&mdash;&mdash;如果您希望访问或编辑您的账号中的个人资料信息、更改您的密码等，您可以登陆账号通过&ldquo;个人信息&rdquo;执行此类操作。</p>
                    <p>搜索信息&mdash;&mdash;您可以登陆账号通过点击搜索框查看或清除您的搜索历史记录、管理其他数据。</p>
                    <p>领用信息&mdash;&mdash;您可以登陆账号通过&ldquo;领用单&rdquo;查阅恁的领用记录等。</p>
                    <p>如果您无法通过上述路径访问该等个人信息，您可以随时通过科颐轩官网的&ldquo;联系我们&rdquo;中获得联系方式，与我们取得联系 。我们将在30天内回复您的访问请求。</p>
                    <p>对于您在使用我们的产品或服务过程中产生的其他个人信息，我们将根据本条&ldquo;（六）响应您的上述请求&rdquo;中的相关安排向您提供。</p>
                    <p>（二）更正您的个人信息</p>
                    <p>当您发现我们处理的关于您的个人信息有错误时，您有权要求我们做出更正或补充。您可以通过&ldquo;（一）访问您的个人信息&rdquo;中列明的方式提出更正或补充申请。</p>
                    <p>（三）删除您的个人信息</p>
                    <p>您可以通过&ldquo;（一）访问您的个人信息&rdquo;中列明的方式删除您的部分个人信息。</p>
                    <p>在以下情形中，您可以向我们提出删除个人信息的请求：</p>
                    <p>1、如果我们处理个人信息的行为违反法律法规；</p>
                    <p>2、如果我们收集、使用您的个人信息，却未征得您的同意；</p>
                    <p>3、如果我们处理个人信息的行为违反了与您的约定；</p>
                    <p>4、如果您不再使用我们的产品或服务，或您注销了账号；</p>
                    <p>5、如果我们不再为您提供产品或服务。</p>
                    <p>若我们决定响应您的删除请求，我们还将同时通知从我们获得您的个人信息的实体，要求其及时删除，除非法律法规另有规定，或这些实体获得您的独立授权。</p>
                    <p>当您从我们的服务中删除信息后，我们可能不会立即备份系统中删除相应的信息，但会在备份更新时删除这些信息。</p>
                    <p>（四）个人信息主体注销账号</p>
                    <p>您可以通过科颐轩官网的&ldquo;联系我们&rdquo;中获得联系方式，与我们取得联系，申请注销账号。</p>
                    <p>在您主动注销账号之后，我们将停止为您提供产品或服务，根据适用法律的要求删除您的个人信息，或是其匿名化处理。</p>
                    <p>（五）约束信息系统自动决策</p>
                    <p>在某些业务功能中，我们可能仅依据信息系统、算法等在内的非人工自动决策机制做出决定。如果这些决定显著影响您的合法权益，您有权要求我们做出解释，我们也将在不侵害科颐轩商业机密或其他用户权益、社会公共利益的前提下提供申诉方法。
                    </p>
                    <p>（六）响应您的上述请求</p>
                    <p>为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。</p>
                    <p>我们将在30天内做出答复。如您不满意，还可以通过科颐轩官网的&ldquo;联系我们&rdquo;中获得联系方式发起投诉。</p>
                    <p>对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。
                    </p>
                    <p>在以下情形中，按照法律法规要求，我们将无法响应您的请求：</p>
                    <p>1、与国家安全、国防安全有关的；</p>
                    <p>2、与公共安全、公共卫生、重大公共利益有关的；</p>
                    <p>3、与犯罪侦查、起诉、审判和执行判决等有关的；</p>
                    <p>4、有充分证据表明个人信息主体存在主观恶意或滥用权利的；</p>
                    <p>5、响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的；</p>
                    <p>6、涉及商业机密的。</p>
                    <p>六、我们如何处理未成年人的个人信息</p>
                    <p>我们的产品、网站和服务主要面向成年人。如果没有父母或监护人的同意，未成年人不得创建自己的用户账号。如您为未成年人的，建议您请您的父母或监护人仔细阅读本隐私权政策，并在征得您的父母或监护人同意的前提下使用我们的服务或向我们提供信息。
                    </p>
                    <p>对于经父母或监护人同意使用我们的产品或服务而收集未成年人个人信息的情况，我们只会在法律法规允许、父母或监护人明确同意或者保护未成年人所必要的情况下使用、共享、转让或披露此信息。</p>
                    <p>尽管当地法律和习俗对未成年人的定义不同，但我们将不满 14 周岁的任何人均视为未成年人。</p>
                    <p>如果我们发现未成年人在未事先获得可证实的父母或监护人同意的情况下收集了未成年人的个人信息，则会设法尽快删除相关数据。</p>
                    <p><strong>七、您的个人信息如何在全球范围转移</strong></p>
                    <p>我们在中华人民共和国境内收集和产生的个人信息，将存储在中华人民共和国境内。</p>
                    <p>由于我们通过遍布全球的资源和服务器提供产品或服务，这意味着，在获得您的授权同意后，您的个人信息可能会被转移到您使用产品或服务所在国家/地区的境外管辖区，或者受到来自这些管辖区的访问。</p>
                    <p>此类管辖区可能设有不同的数据保护法，甚至未设立相关法律。在此类情况下，我们会确保您的个人信息得到在中华人民共和国境内足够同等的保护。例如，我们会请求您对跨境转移个人信息的同意，或者在跨境数据转移之前实施数据去标识化等安全举措。
                    </p>
                    <p><strong>八、本隐私权政策如何更新</strong></p>
                    <p>我们可能适时会对本隐私权政策进行调整或变更。</p>
                    <p>未经您明确同意，我们不会限制您按照本隐私权政策所应享有的权利。调整或变更后的隐私权政策一经公布，立即取代原政策并自动生效。如您不同意相关变更，应当立即就停止使用创新云平台服务，如您继续使用创新云平台服务或进行任何网站活动，即表示您已经接受经修订的政策。
                    </p>
                    <p><strong>九、如何联系我们</strong></p>
                    <p>如您有其他的意见、建议或投诉等相关问题，可以通过以下方式与我们联系。</p>
                    <p>邮箱：<a class="hyperlink" href="mailto:zk_share@vip.163.com"
                            target="_Blank">zk_share@vip.163.com</a>；电话：(020)38987225</p>
                    <p>我们将尽快审核所涉问题，并在验证您的用户身份后的30天内回复您的请求。</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BGImage from '@/assets/ball-background.png';
export default {
    data() {
        return {
            BGImage
        }
    }
}
</script>

<style lang="less" scoped>
.intro-header {
    width: 100%;
    height: 250px;
    width: 100%;
    background: url(../../../assets/ball-background.png);
}
</style>